@import url("https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@100;300;400;500;700&display=swap");
:root {
  --brown-rgb: 200, 163, 102;
}

*, *::before, *::after {
  box-sizing: border-box;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(219, 213, 213, 0.7882352941);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

.l {
  box-shadow: inset 0 0 0 1px red !important;
}

.w-100 {
  width: 100%;
}

.gray {
  color: #949494;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fs-60 {
  font-size: 60px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.text-brown {
  color: #c8a366;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.o-25 {
  opacity: 0.25;
}

.o-30 {
  opacity: 0.3;
}

.o-50 {
  opacity: 0.5;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.jc-center {
  justify-content: center;
}

.jc-between {
  justify-content: space-between;
}

.ai-center {
  align-items: center;
}

.jc-end {
  justify-content: end;
}

.d-ib {
  display: inline-block;
}

.minw-300 {
  min-width: 300px;
}

.maxw-300 {
  max-width: 300px;
}

.maxw-350 {
  max-width: 350px;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.top-6 {
  top: 6px;
}

.right-0 {
  right: 0;
}

.right-5 {
  right: 5px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.pe-none {
  pointer-events: none;
}

.logi-section {
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 237, 207, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.logi-section .login-shape1 {
  position: absolute;
  top: 0;
  right: 0;
}
.logi-section .login-shape2,
.logi-section .login-shape3 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.logi-section form {
  width: 100%;
  max-width: 554px;
  background: #ffffff;
  border-radius: 26px;
  padding: 52px;
  position: relative;
  z-index: 10;
  font-family: "Roboto", sans-serif;
}
.logi-section form .logo-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logi-section form .logo-main .logo-box {
  width: 140px;
  height: 140px;
  background: #ffffff;
  border-radius: 15px;
  margin-top: -120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logi-section form .logo-main .logo-box .logo {
  width: 110px;
  height: 110px;
}

.input-wrapper {
  position: relative;
}
.input-wrapper .text-input-color {
  color: #c6c6c6;
}
.input-wrapper input {
  width: 100%;
  height: 58px;
  border-radius: 58px;
  border: 1.2px solid #c8a366;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding-left: 30px;
  font-weight: 400;
  font-size: 22px;
  outline: none;
}
.input-wrapper img {
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  right: 10px;
  position: absolute;
  pointer-events: none;
}
.input-wrapper .button {
  width: 100%;
  height: 58px;
  border-radius: 58px;
  background-color: #c8a366;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: 22px;
  outline: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.input-wrapper .input-icon {
  margin-right: 20px;
}

nav {
  width: calc(100% - 268px);
  left: 286px;
  height: 70px;
  position: fixed;
  top: 0;
  background: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  z-index: 6;
}

.wrapper {
  min-height: calc(100vh - 80px);
  padding-left: 305px;
  padding-top: 86px;
  padding-right: 37px;
  padding-bottom: 37px;
  position: relative;
  z-index: 18;
}

.wrapper-box {
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
}
.wrapper-box .input-search {
  position: relative;
}
.wrapper-box .input-search input {
  width: 200px;
  height: 34px;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  padding-left: 12px;
  font-weight: 400;
  font-size: 12px;
  outline: none;
  color: #e0e0e0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.wrapper-box .input-search input:focus {
  border-color: #c8a366;
  box-shadow: 0 0 0 0.25rem rgba(var(--brown-rgb), 0.3);
}
.wrapper-box .input-search img {
  right: 50px;
  pointer-events: none;
}
.wrapper-box .bell-icon {
  padding-left: 62px;
  padding-right: 30px;
  border-right: 1px solid #b3b3b3;
}
.wrapper-box .bell-icon .input-icon {
  width: 26px;
  height: 26px;
  padding-right: 50px;
}

.inner-box {
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(200, 163, 102, 0.18);
  border-radius: 20px;
  padding: 30px;
}

.heading {
  font-weight: 700;
  font-size: 32px;
  padding-bottom: 20px;
  color: #c8a366;
}

.headingTable {
  font-weight: 700;
  font-size: 24px;
  color: #c8a366;
  text-align: center;
  position: relative;
}
.headingTable .form-check-input[type=checkbox] {
  border-radius: 2px;
}
.headingTable .form-check-input:checked {
  background-color: #c8a366;
  border-color: #c8a366;
}
.headingTable .form-check-input:focus {
  border-color: #c8a366;
  box-shadow: none;
}

.input-box {
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  width: 100%;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-box:focus {
  border-color: #c8a366;
  box-shadow: 0 0 0 0.25rem rgba(var(--brown-rgb), 0.3);
}

.form-check {
  margin-right: 15px;
}

.button {
  display: inline-block;
  padding: 0.56rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  height: -moz-fit-content;
  height: fit-content;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid #212529;
  border-radius: 0.375rem;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-brown {
  background-color: #c8a366 !important;
  color: #ffffff !important;
  border-color: #c8a366 !important;
}
.button-brown:hover, .button-brown:focus {
  background-color: #aa741f !important;
  border-color: #aa741f !important;
  color: white !important;
}
.button-brown:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--brown-rgb), 0.5) !important;
}
.button-brown:disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.button-default {
  border-color: #b3b3b3;
}
.button-default:hover, .button-default:focus {
  background-color: #eee;
  border-color: #999;
}
.button-default:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.shape-right-buttom {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
}

.edit-del-box button {
  border: none;
  background: none;
  cursor: pointer;
}

.border-line {
  width: 100px;
  height: 2px;
  background-color: #dcdcdc;
  margin: 0px 5px;
}

.dash-details {
  width: 100%;
  max-width: 852px;
}
.dash-details h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: #949494;
  display: flex;
  justify-content: center;
  padding-bottom: 29px;
}
.dash-details .dash-card {
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(200, 163, 102, 0.15);
  border-radius: 8px;
}
.dash-details .card-logo,
.dash-details .card-txt {
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dash-details .card-logo {
  box-shadow: 0px 1px 15px rgba(200, 163, 102, 0.15);
  border-radius: 8px;
}
.dash-details .card-txt {
  border-radius: 50%;
  border: 3px solid #c8a366;
  color: #949494;
  font-weight: 700;
  font-size: 42px;
}

.dash-list {
  width: 852px;
  top: 499px;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(200, 163, 102, 0.15);
  border-radius: 8px;
}
.dash-list ul {
  padding: 0;
  margin: 0;
}
.dash-list ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  color: #949494;
}

.upload-box h3,
.upload-box h6 {
  display: flex;
  justify-content: center;
  color: #949494;
}
.upload-box h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
}
.upload-box h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding-bottom: 30px;
}

.drag-box {
  position: relative;
  display: block;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 15px 35px 15px;
  border: 2px dashed rgba(148, 148, 148, 0.7);
  border-radius: 6px;
}
.drag-box .icon {
  margin: 0px;
  font-size: 120px;
  color: rgba(200, 163, 102, 0.5);
}
.drag-box span {
  position: relative;
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #949494;
  pointer-events: none;
  padding: 0px 0px 15px 0px;
}
.drag-box input {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.drag-box .button {
  cursor: pointer;
  z-index: 5;
  padding: 10px 40px;
}

.spinner-box {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
}

.loader-spinner {
  width: 40px;
  height: 40px;
}
.loader-spinner svg {
  width: 50px;
  fill: none;
}

.load {
  transform-origin: 50% 50%;
  stroke-dasharray: 0.7 0.3;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: rgb(0, 92, 132);
}
.load.one {
  animation: load 1.5s infinite ease-in;
  animation-direction: reverse;
}
.load.two {
  fill: rgb(0, 92, 132);
}
.load.three {
  animation: load 1.5s infinite;
}

@keyframes load {
  100% {
    transform: rotate(360deg);
  }
}
.spinner-row {
  height: 50px;
}

aside {
  width: 268px;
  height: 100%;
  box-shadow: 4px 0px 11px rgba(200, 163, 102, 0.18);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
aside .dashboard-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 37px;
}
aside .dashboard-logo a {
  text-decoration: none;
}
aside .dashboard-logo a img {
  height: 51px;
}
aside .dashboard-logo button {
  background: transparent;
  cursor: pointer;
  color: #c8a366;
  padding: 0;
  border: none;
  outline: none;
  line-height: 1;
  font-size: 30px;
}
aside .dashboard-logo button:hover {
  color: #aa741f;
}
aside ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
aside ul a {
  text-decoration: none;
  min-height: 44px;
  padding-left: 37px;
  padding-right: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: #c8a366;
  font-size: 22px;
  font-weight: 700;
  transition: 300ms;
  border-radius: 5px;
  position: relative;
}
aside ul a::after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 85%;
  right: 0px;
  top: 8%;
  background: #ffffff;
  border-radius: 6px;
  z-index: 2;
}
aside ul a.active, aside ul a:hover {
  background: #c8a366;
  color: #ffffff;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
}
aside ul a span {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
aside ul a strong {
  padding-left: 12px;
  font-weight: bold;
}
aside ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
aside ul .nav-link {
  text-decoration: none;
  height: 44px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  padding-left: 37px;
  padding-right: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: #c8a366;
  font-size: 22px;
  font-weight: 700;
  transition: 300ms;
  border-radius: 5px;
  position: relative;
}
aside ul .nav-link::after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 32px;
  right: 0px;
  top: 6px;
  background: #ffffff;
  border-radius: 6px;
  z-index: 2;
}
aside ul .nav-link.active, aside ul .nav-link:hover {
  background: #c8a366;
  color: #ffffff;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
}
aside ul .nav-link.nav-toggle::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-left: 4px solid #aa741f;
  border-bottom: 4px solid #aa741f;
  right: 15px;
  top: calc(50% - 8px);
  transform: rotate(-45deg);
  transition: 300ms;
}
aside ul .nav-link.nav-toggle:hover::before {
  border-left-color: #fff;
  border-bottom-color: #fff;
}
aside ul .nav-link.nav-toggle.active {
  color: #c8a366;
  background-color: #fff;
}
aside ul .nav-link.nav-toggle.active::before {
  top: calc(50% - 3px);
  transform: rotate(-224deg);
  border-left: 4px solid #aa741f;
  border-bottom: 4px solid #aa741f;
}
aside ul .nav-link span {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
aside ul .nav-link strong {
  padding-left: 12px;
  pointer-events: none;
  font-weight: bold;
}
aside ul .navmore-link {
  position: relative;
  background-color: #fff;
  width: calc(100% - 20px);
  margin: 0 auto;
  box-shadow: 0px 0px 5px rgba(200, 163, 102, 0.24);
  border-radius: 5px;
  padding: 0px 0px 0px 50px;
  display: none;
}
aside ul .navmore-link.active {
  display: block;
}
aside ul .navmore-link a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 10px 14px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #c8a366;
}
aside ul .navmore-link a:hover {
  color: #ffffff;
}
aside ul .navmore-link a.active {
  background: #c8a366;
  color: #ffffff;
  border-radius: 5px;
  border-bottom: 1px solid transparent;
}
aside ul .navmore-link a:last-child {
  border-bottom: none;
}

.shrink-menu aside {
  width: 110px;
}
.shrink-menu aside .dashboard-logo {
  flex-direction: column;
}
.shrink-menu aside .dashboard-logo button {
  margin-top: 10px;
}
.shrink-menu aside ul {
  word-break: break-all;
}
.shrink-menu aside ul .nav-link strong {
  display: none !important;
}
.shrink-menu aside .navmore-link {
  padding: 0px;
}
.shrink-menu aside .navmore-link a {
  padding: 10px 5px;
  font-size: 13px;
}
.shrink-menu aside strong {
  display: none;
}
.shrink-menu .wrapper {
  padding-left: 147px;
}
.shrink-menu *::-webkit-scrollbar {
  width: 10px;
}
.shrink-menu *::-webkit-scrollbar-thumb {
  border: 2px solid #ffffff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-undo {
  border: none;
  background-color: transparent;
  color: #949494;
  cursor: pointer;
}
.btn-undo:hover {
  color: #aa741f;
}
.btn-undo:active {
  color: #c8a366;
}

.tbl-scroller {
  margin-top: 20px;
  max-height: 450px;
  overflow-y: scroll;
  width: 100%;
  overflow-x: scroll;
}

@media screen and (min-width: 1920px) {
  .tbl-scroller {
    max-height: 550px;
    overflow-y: scroll;
    width: 100%;
    overflow-x: scroll;
  }
}
.table-wrapper {
  width: 100%;
  background: #fdfdfd;
  color: #949494;
  border-collapse: collapse;
}
.table-wrapper thead {
  position: sticky;
  top: 0;
  background-color: #fff;
}
.table-wrapper thead,
.table-wrapper tbody {
  box-shadow: inset 0 0 0 1px #dcdcdc !important;
  border-radius: 8px !important;
}
.table-wrapper tbody.tbody-gap {
  opacity: 0;
  line-height: 5px;
}
.table-wrapper tbody.tbody-gap td {
  opacity: 0;
  line-height: 5px;
  padding-bottom: 5px;
  border: 0;
}
.table-wrapper tbody tr td {
  border-bottom: 1px solid #dcdcdc;
}
.table-wrapper tbody tr button {
  background-color: transparent;
  color: #949494;
}
.table-wrapper tbody tr button:hover {
  color: #c8a366;
}
.table-wrapper tbody tr:last-child td {
  border-bottom: none;
}
.table-wrapper tr {
  color: #949494;
}
.table-wrapper tr th {
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 16px;
  text-align: center;
  padding: 12px 8px;
  width: auto;
  min-width: 140px;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  text-wrap: nowrap;
}
.table-wrapper tr td {
  text-align: center;
  padding: 12px 12px;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}
.table-wrapper tr button {
  cursor: pointer;
  color: #ffffff;
  border: none;
  padding: 4px 12px;
  border-radius: 4px;
}
.table-wrapper tr .active-btn {
  background: #26c50c;
}
.table-wrapper tr .active-btn:active {
  background: rgba(37, 185, 14, 0.8196078431);
}
.table-wrapper tr .deactive-btn {
  background: #ec3f09;
}
.table-wrapper tr .deactive-btn:active {
  background: rgba(236, 82, 36, 0.8235294118);
}
.table-wrapper tr th:first-child {
  border-left: none;
}
.table-wrapper tr th:last-child {
  border-right: none;
}
.table-wrapper tr td:first-child {
  border-left: none;
}
.table-wrapper tr td:last-child {
  border-right: none;
}

.table-header {
  background: #fdfdfd;
  border: 1px solid #dcdcdc;
  color: #949494;
  border-radius: 8px;
  border-collapse: collapse;
}
.table-header tr {
  border-bottom: 1pt solid #dcdcdc;
}

#cars {
  border: 1px solid #dcdcdc;
  outline: none;
  border-radius: 4px;
  color: #949494;
  padding: 5px;
}

.pagination {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.pagination .page-leftright {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  outline: none;
  background: transparent;
  text-decoration: none;
  color: #949494;
  cursor: pointer;
}
.pagination .page-leftright:hover {
  color: #c8a366;
}
.pagination span {
  padding: 0 12px;
  color: #949494;
  font-size: 20px;
}

.accordion-header {
  display: flex;
  align-items: start;
  width: 100%;
  /* Ensure the headers align properly */
  /* Adjust individual items as needed */
}
.accordion-header > div {
  flex-grow: 1;
  flex-basis: 150px;
  /* Ensure consistent width */
  text-align: start;
}
.accordion-header .bold {
  flex-basis: 200px;
  /* Adjusted to a wider size */
  text-align: left;
  /* Align the date to the left */
}
.accordion-header .paid-amount,
.accordion-header .received-amount {
  flex-basis: 150px;
  /* Consistent width for amount columns */
  text-align: right;
  /* Right-align the numeric values for clarity */
}
.accordion-header .fw-600 {
  font-weight: 600;
}

.divider {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
}
.divider::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #ccc;
}
.divider:last-child::after {
  display: none;
}

.accordion-button:not(.collapsed) {
  background-color: rgba(200, 162, 102, 0.4588235294) !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.text-bg-paid {
  color: #fff !important;
  background-color: #26c50c !important;
}

.text-bg-unpaid {
  color: #fff !important;
  background-color: #f51b1b !important;
}

.text-bg-partial {
  color: #fff !important;
  background-color: #f58c1b !important;
}

.text-bg-failed {
  color: #fff !important;
  background-color: #f51b1b !important;
}

.text-brown {
  color: #c8a366 !important;
}

.text-gray {
  color: #949494 !important;
}

.modal-min-w90 .modal-dialog {
  min-width: 90% !important;
}

.modal-min-h90 .modal-content {
  min-height: 90vh !important;
}

.tbl-br-l {
  border-left: 2px solid #dcdcdc;
}

.text-uderline {
  text-decoration: underline !important;
}

.select-box .css-13cymwt-control {
  border-radius: 6px !important;
}
.select-box .css-t3ipsp-control {
  border-radius: 6px !important;
  border-color: #c8a366 !important;
  box-shadow: 0 0 0 0.25rem rgba(var(--brown-rgb), 0.3) !important;
}

.child-accordion .accordion-button::after {
  display: none !important;
}

.MobileViewWrapper {
  display: none;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}
.MobileViewWrapper .MobileNav {
  z-index: 99999 !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  margin: 0 auto;
  max-width: 768px;
}
.MobileViewWrapper .MobileNav .logBox {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.MobileViewWrapper .MobileNav .logBox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.MobileViewWrapper .MobileNav h1 {
  font-size: 20px;
}
.MobileViewWrapper .MobileContent {
  z-index: 0 !important;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.MobileViewWrapper .MobileContent:last-child {
  margin-bottom: 54px;
}
.MobileViewWrapper .MobileContent:first-child {
  margin-top: 110px;
}
.MobileViewWrapper .MobileFtr {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  margin: 0 auto;
  max-width: 768px;
}
.MobileViewWrapper .input-box {
  color: #2a2a2a;
}

@media screen and (max-width: 768px) {
  .DeskTopView {
    display: none;
  }
  .MobileViewWrapper {
    display: block;
  }
}
.thankBox {
  height: 100vh;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thankBox .content .ThankImg {
  width: 100%;
  max-width: 454px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.thankBox .content .ThankImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.srtd {
  max-width: -moz-min-content !important;
  max-width: min-content !important;
  min-width: -moz-min-content !important;
  min-width: min-content !important;
}

.bg-brown {
  background-color: #aa741f !important;
  color: #fff !important;
}
.bg-brown:hover {
  background-color: #c8a366 !important;
}
.bg-brown:disabled {
  background-color: #c8a366 !important;
  color: #fff !important;
}

.TblBtn {
  border: none;
  padding: 0px;
  margin: 0px;
  background: none;
  color: #c8a366;
  margin-left: 10px;
}
.TblBtn:hover {
  color: #a57d3d;
}

.paid-btn {
  background: #26c50c !important;
  color: #ffffff !important;
}
.paid-btn:hover {
  background: #1f8610 !important;
}

.ant-modal-title {
  text-align: center;
}

.button-success {
  background-color: #28a745 !important;
  color: white;
}

.button-danger {
  background-color: #dc3545 !important;
  color: white;
}/*# sourceMappingURL=index.css.map */