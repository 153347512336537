@import url("https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@100;300;400;500;700&display=swap");
// font-family: 'Anton', sans-serif;
// font-family: 'Roboto', sans-serif;
$brown: #c8a366;
$brown-dark: #aa741f;

:root {
  --brown-rgb: 200, 163, 102;
}

* {

  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  // background: #c6c6c6;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbd5d5c9;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

.l {
  box-shadow: inset 0 0 0 1px red !important;
}

.w-100 {
  width: 100%;
}

.gray {
  color: #949494;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fs-60 {
  font-size: 60px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.text-brown {
  color: $brown;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.o-25 {
  opacity: 0.25;
}

.o-30 {
  opacity: 0.3;
}

.o-50 {
  opacity: 0.5;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.jc-center {
  justify-content: center;
}

.jc-between {
  justify-content: space-between;
}

.ai-center {
  align-items: center;
}

.jc-end {
  justify-content: end;
}

.d-ib {
  display: inline-block;
}

.minw-300 {
  min-width: 300px;
}

.maxw-300 {
  max-width: 300px;
}

.maxw-350 {
  max-width: 350px;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.top-6 {
  top: 6px;
}

.right-0 {
  right: 0;
}

.right-5 {
  right: 5px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.pe-none {
  pointer-events: none;
}

.logi-section {
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 237, 207, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .login-shape1 {
    position: absolute;
    top: 0;
    right: 0;
  }

  .login-shape2,
  .login-shape3 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  form {
    width: 100%;
    max-width: 554px;
    background: #ffffff;
    border-radius: 26px;
    padding: 52px;
    position: relative;
    z-index: 10;
    font-family: "Roboto", sans-serif;

    .logo-main {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo-box {
        width: 140px;
        height: 140px;
        // border: 1px solid red;
        background: #ffffff;
        border-radius: 15px;
        margin-top: -120px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
          width: 110px;
          height: 110px;
        }
      }
    }
  }
}

.input-wrapper {
  position: relative;

  .text-input-color {
    color: #c6c6c6;
  }

  input {
    width: 100%;
    height: 58px;
    border-radius: 58px;
    border: 1.2px solid #c8a366;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding-left: 30px;
    font-weight: 400;
    font-size: 22px;
    outline: none;
  }

  img {
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    right: 10px;
    position: absolute;
    pointer-events: none;
  }

  .button {
    width: 100%;
    height: 58px;
    border-radius: 58px;
    background-color: #c8a366;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    font-weight: 400;
    font-size: 22px;
    outline: none;
    border: none;
    // outline: #C8A366;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-weight: 700;
    font-weight: bold;
  }

  .input-icon {
    margin-right: 20px;
  }
}

// ---- Start Dashboard ------
nav {
  width: calc(100% - 268px);
  left: calc(268px + 18px);
  height: 70px;
  // padding-left: 342px;
  position: fixed;
  top: 0;
  background: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  z-index: 6;
}

.wrapper {
  min-height: calc(100vh - 80px);
  padding-left: calc(268px + 37px);
  padding-top: 86px;
  padding-right: 37px;
  padding-bottom: 37px;
  position: relative;
  z-index: 18;
}

.wrapper-box {
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.18);
  border-radius: 20px;

  .input-search {
    position: relative;

    input {
      width: 200px;
      height: 34px;
      border: 1px solid #f2f2f2;
      border-radius: 6px;
      padding-left: 12px;
      font-weight: 400;
      font-size: 12px;
      outline: none;
      color: #e0e0e0;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        border-color: $brown;
        box-shadow: 0 0 0 0.25rem rgba(var(--brown-rgb), 0.3);
      }
    }

    img {
      // width: 11.25px;
      // height: 11.25px;
      // top: calc(50% - 6px);
      right: 50px;
      // position: absolute;
      pointer-events: none;
    }
  }

  .bell-icon {
    padding-left: 62px;
    padding-right: 30px;
    border-right: 1px solid #b3b3b3;

    .input-icon {
      width: 26px;
      height: 26px;
      padding-right: 50px;
    }
  }
}

.inner-box {
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(200, 163, 102, 0.18);
  border-radius: 20px;
  padding: 30px;
}

.heading {
  font-weight: 700;
  font-size: 32px;
  padding-bottom: 20px;
  color: #c8a366;
}

.headingTable {
  font-weight: 700;
  font-size: 24px;
  color: #c8a366;
  text-align: center;
  position: relative;

  .form-check-input[type=checkbox] {
    border-radius: 2px;
  }

  .form-check-input:checked {
    background-color: #c8a366;
    border-color: #c8a366;
  }

  .form-check-input:focus {
    border-color: #c8a366;
    box-shadow: none;

  }
}

.input-box {
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  width: 100%;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: $brown;
    box-shadow: 0 0 0 0.25rem rgba(var(--brown-rgb), 0.3);
  }
}

.form-check {
  margin-right: 15px;
}

.button {
  display: inline-block;
  padding: 0.56rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  height: fit-content;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid #212529;
  border-radius: 0.375rem;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-brown {
  background-color: $brown !important;
  color: #ffffff !important;
  border-color: $brown !important;

  &:hover,
  &:focus {
    background-color: $brown-dark !important;
    border-color: $brown-dark !important;
    color: white !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--brown-rgb), 0.5) !important;
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
}

.button-default {
  border-color: #b3b3b3;

  &:hover,
  &:focus {
    background-color: #eee;
    border-color: #999;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.15);
  }
}

.shape-right-buttom {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
}

.edit-del-box {
  button {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.border-line {
  width: 100px;
  height: 2px;
  background-color: #dcdcdc;
  margin: 0px 5px;
}

.dash-details {
  width: 100%;
  max-width: 852px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    color: #949494;
    display: flex;
    justify-content: center;
    padding-bottom: 29px;
  }

  .dash-card {
    width: 380px;
    background: #ffffff;
    box-shadow: 0px 1px 15px rgba(200, 163, 102, 0.15);
    border-radius: 8px;
  }

  .card-logo,
  .card-txt {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-logo {
    box-shadow: 0px 1px 15px rgba(200, 163, 102, 0.15);
    border-radius: 8px;
  }

  .card-txt {
    border-radius: 50%;
    border: 3px solid #c8a366;
    color: #949494;
    font-weight: 700;
    font-size: 42px;
  }
}

.dash-list {
  width: 852px;
  top: 499px;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(200, 163, 102, 0.15);
  border-radius: 8px;

  ul {
    padding: 0;
    margin: 0;

    li {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;
      color: #949494;
    }
  }
}

.upload-box {

  // width: 400px;
  // background: #FFFFFF;
  // box-shadow: 0px 1px 15px rgba(200, 163, 102, 0.15);
  // border-radius: 8px;
  h3,
  h6 {
    display: flex;
    justify-content: center;
    color: #949494;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
  }

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 30px;
  }
}

.drag-box {
  position: relative;
  display: block;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 15px 35px 15px;
  border: 2px dashed rgba(148, 148, 148, 0.7);
  border-radius: 6px;

  .icon {
    margin: 0px;
    font-size: 120px;
    color: rgba(200, 163, 102, 0.5);
  }

  span {
    position: relative;
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #949494;
    pointer-events: none;
    padding: 0px 0px 15px 0px;
  }

  input {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .button {
    cursor: pointer;
    z-index: 5;
    padding: 10px 40px;
  }
}

// ---- End Dashboard ------
$color: rgb(0, 92, 132);

.spinner-box {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
}

.loader-spinner {
  width: 40px;
  height: 40px;

  svg {
    width: 50px;
    fill: none;
  }
}

.load {
  transform-origin: 50% 50%;
  stroke-dasharray: 0.7 0.3;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: $color;

  &.one {
    animation: load 1.5s infinite ease-in;
    animation-direction: reverse;
  }

  &.two {
    fill: $color;
  }

  &.three {
    animation: load 1.5s infinite;
  }
}

@keyframes load {
  100% {
    transform: rotate(360deg);
  }
}

.spinner-row {
  height: 50px;
}

// aside
aside {
  width: 268px;
  height: 100%;
  box-shadow: 4px 0px 11px rgba(200, 163, 102, 0.18);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: start;

  .dashboard-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 37px;

    a {
      text-decoration: none;

      img {
        height: 51px;
      }
    }

    button {
      background: transparent;
      cursor: pointer;
      color: $brown;
      padding: 0;
      border: none;
      outline: none;
      line-height: 1;
      font-size: 30px;

      &:hover {
        color: $brown-dark;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    a {
      text-decoration: none;
      min-height: 44px;
      padding-left: 37px;
      padding-right: 10px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      color: $brown;
      font-size: 22px;
      font-weight: 700;
      transition: 300ms;
      border-radius: 5px;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 4px;
        height: 85%;
        right: 0px;
        top: 8%;
        background: #ffffff;
        border-radius: 6px;
        z-index: 2;
      }

      &.active,
      &:hover {
        background: $brown;
        color: #ffffff;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
      }

      span {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      strong {
        padding-left: 12px;
        font-weight: bold;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .nav-link {
      text-decoration: none;
      height: 44px;
      min-height: fit-content;
      padding-left: 37px;
      padding-right: 10px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      color: $brown;
      font-size: 22px;
      font-weight: 700;
      transition: 300ms;
      border-radius: 5px;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 4px;
        height: 32px;
        right: 0px;
        top: 6px;
        background: #ffffff;
        border-radius: 6px;
        z-index: 2;
      }

      &.active,
      &:hover {
        background: $brown;
        color: #ffffff;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
      }

      &.nav-toggle {
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          border-left: 4px solid $brown-dark;
          border-bottom: 4px solid $brown-dark;
          right: 15px;
          top: calc(50% - 8px);
          transform: rotate(-45deg);
          transition: 300ms;
        }

        &:hover {
          &::before {
            border-left-color: #fff;
            border-bottom-color: #fff;
          }
        }

        &.active {
          color: $brown;
          background-color: #fff;

          &::before {
            top: calc(50% - 3px);
            transform: rotate(-224deg);
            border-left: 4px solid $brown-dark;
            border-bottom: 4px solid $brown-dark;
          }
        }
      }

      span {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
      }

      strong {
        padding-left: 12px;
        pointer-events: none;
        font-weight: bold;
      }
    }

    .navmore-link {
      position: relative;
      background-color: #fff;
      width: calc(100% - 20px);
      margin: 0 auto;
      box-shadow: 0px 0px 5px rgba(200, 163, 102, 0.24);
      border-radius: 5px;
      padding: 0px 0px 0px 50px;
      display: none;

      &.active {
        display: block;
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none;
        padding: 10px 14px;
        border-bottom: 1px solid #ddd;
        font-size: 16px;
        color: $brown;

        &:hover {
          color: #ffffff;
        }

        &.active {
          background: $brown;
          color: #ffffff;
          border-radius: 5px;
          border-bottom: 1px solid transparent;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.shrink-menu {
  aside {
    width: 110px;

    .dashboard-logo {
      flex-direction: column;

      button {
        margin-top: 10px;
      }
    }

    ul {
      .nav-link {
        strong {
          display: none !important;
        }
      }

      word-break: break-all;
    }

    .navmore-link {
      padding: 0px;

      a {
        padding: 10px 5px;
        font-size: 13px;
      }
    }

    strong {
      display: none;
    }
  }

  .wrapper {
    padding-left: calc(110px + 37px);
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-thumb {
    border: 2px solid #ffffff;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-undo {
  border: none;
  background-color: transparent;
  color: #949494;
  cursor: pointer;

  &:hover {
    color: $brown-dark;
  }

  &:active {
    color: $brown;
  }
}

.tbl-scroller {
  margin-top: 20px;
  max-height: 450px;
  overflow-y: scroll;
  width: 100%;
  // max-width: 1600px;
  overflow-x: scroll;
}

@media screen and (min-width: 1920px) {
  .tbl-scroller {
    max-height: 550px;
    overflow-y: scroll;
    width: 100%;
    // max-width: 1600px;
    overflow-x: scroll;
  }
}

.table-wrapper {
  width: 100%;
  background: #fdfdfd;
  color: #949494;
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    // z-index: 10;
  }

  thead,
  tbody {
    box-shadow: inset 0 0 0 1px #dcdcdc !important;
    border-radius: 8px !important;
  }

  tbody {
    &.tbody-gap {
      opacity: 0;
      line-height: 5px;

      td {
        opacity: 0;
        line-height: 5px;
        padding-bottom: 5px;
        border: 0;
      }
    }

    tr {
      td {
        border-bottom: 1px solid #dcdcdc;
      }

      button {
        background-color: transparent;
        color: #949494;

        &:hover {
          color: $brown;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  tr {
    color: #949494;

    th {
      font-style: normal;
      font-weight: bold;
      color: black;
      font-size: 16px;
      text-align: center;
      // padding: 12px 0px;
      padding: 12px 8px;
      width: auto;
      min-width: 140px;
      border-left: 1px solid #dcdcdc;
      border-right: 1px solid #dcdcdc;
      text-wrap: nowrap;
    }

    td {
      text-align: center;
      padding: 12px 12px;
      border-left: 1px solid #dcdcdc;
      border-right: 1px solid #dcdcdc;
    }

    button {
      cursor: pointer;
      color: #ffffff;
      border: none;
      padding: 4px 12px;
      border-radius: 4px;
    }

    .active-btn {
      background: #26c50c;

      &:active {
        background: #25b90ed1;
      }
    }

    .deactive-btn {
      background: #ec3f09;

      &:active {
        background: #ec5224d2;
      }
    }

    // hide the left and right border of the first and last th
    th:first-child {
      border-left: none;
    }

    th:last-child {
      border-right: none;
    }

    td:first-child {
      border-left: none;
    }

    td:last-child {
      border-right: none;
    }
  }
}

.table-header {
  background: #fdfdfd;
  border: 1px solid #dcdcdc;
  color: #949494;
  border-radius: 8px;
  border-collapse: collapse;

  tr {
    border-bottom: 1pt solid #dcdcdc;
  }
}

#cars {
  border: 1px solid #dcdcdc;
  outline: none;
  border-radius: 4px;
  color: #949494;
  padding: 5px;
}

.pagination {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .page-leftright {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: none;
    outline: none;
    background: transparent;
    text-decoration: none;
    color: #949494;
    cursor: pointer;

    &:hover {
      color: $brown;
    }
  }

  span {
    padding: 0 12px;
    color: #949494;
    font-size: 20px;
  }
}

.accordion-header {
  display: flex;
  align-items: start;
  width: 100%;

  // padding: 5px 0;
  // border-bottom: 1px solid #ccc; /* Optional: adds a bottom border for separation */
  /* Ensure the headers align properly */
  >div {
    flex-grow: 1;
    flex-basis: 150px;
    /* Ensure consistent width */
    text-align: start;
  }

  /* Adjust individual items as needed */
  .bold {
    flex-basis: 200px;
    /* Adjusted to a wider size */
    text-align: left;
    /* Align the date to the left */
  }

  .paid-amount,
  .received-amount {
    flex-basis: 150px;
    /* Consistent width for amount columns */
    text-align: right;
    /* Right-align the numeric values for clarity */
  }

  .fw-600 {
    font-weight: 600;
  }
}

.divider {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #ccc;
  }

  &:last-child::after {
    display: none;
  }
}

.accordion-button:not(.collapsed) {
  background-color: #c8a26675 !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

// new scss
.text-bg-paid {
  color: #fff !important;
  background-color: #26c50c !important;
}

.text-bg-unpaid {
  color: #fff !important;
  background-color: #f51b1b !important;
}

.text-bg-partial {
  color: #fff !important;
  background-color: #f58c1b !important;
}

.text-bg-failed {
  color: #fff !important;
  background-color: #f51b1b !important;
}

.text-brown {
  color: #c8a366 !important;
}

.text-gray {
  color: #949494 !important;
}

.modal-min-w90 {
  .modal-dialog {
    min-width: 90% !important;
  }
}

.modal-min-h90 {
  .modal-content {
    min-height: 90vh !important;
  }
}

.tbl-br-l {
  border-left: 2px solid #dcdcdc;
}

.text-uderline {
  text-decoration: underline !important;
}

.select-box {
  .css-13cymwt-control {
    border-radius: 6px !important;
  }

  .css-t3ipsp-control {
    // border-radius: 4px;
    border-radius: 6px !important;
    // border-color: #2684ff;
    border-color: $brown !important;
    // box-shadow: 0 0 0 1px #2684ff;
    box-shadow: 0 0 0 0.25rem rgba(var(--brown-rgb), 0.3) !important;
  }
}

.child-accordion {
  .accordion-button::after {
    display: none !important;
  }
}

.MobileViewWrapper {
  display: none;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;

  .MobileNav {
    z-index: 99999 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    margin: 0 auto;
    max-width: 768px;

    .logBox {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h1 {
      font-size: 20px;
    }
  }

  .MobileContent {
    z-index: 0 !important;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 10px;
    margin-top: 12px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 54px;
    }

    &:first-child {
      margin-top: 110px;
    }
  }

  .MobileFtr {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    margin: 0 auto;
    max-width: 768px;
  }

  .input-box {
    color: #2a2a2a;
  }
}

@media screen and (max-width: 768px) {
  .DeskTopView {
    display: none;
  }

  .MobileViewWrapper {
    display: block;
  }
}

.thankBox {
  height: 100vh;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    .ThankImg {
      width: 100%;
      max-width: 454px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.srtd {
  max-width: min-content !important;
  min-width: min-content !important;
}

.bg-brown {
  background-color: $brown-dark !important;
  color: #fff !important;

  &:hover {
    background-color: $brown !important;
  }

  ;

  &:disabled {
    background-color: #c8a366 !important;
    color: #fff !important;
  }
}

.TblBtn {
  border: none;
  padding: 0px;
  margin: 0px;
  background: none;
  color: #c8a366;
  margin-left: 10px;
  // transition: 0.1s ease-in;
  // transition: 200ms;

  &:hover {
    color: #a57d3d;
    // transform: translateX(30%);
  }
}

.paid-btn {
  background: #26c50c !important;
  color: #ffffff !important;

  &:hover {
    background: #1f8610 !important;
  }
}

// pie chart title heading
.ant-modal-title {
  text-align: center;
}

.button-success {
  background-color: #28a745 !important;
  color: white;
}

.button-danger {
  background-color: #dc3545 !important;
  color: white;
}